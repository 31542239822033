import React from "react"
import Layout from "../components/layout"
import styles from "./contact.module.styl"
import SEO from "../components/seo"

export default () => {
  return (
    <Layout page="contact">
      <SEO
        title="Contact"
        description={`设计师 蔡依东 联系方式 邮箱：migrate_cyd008@163.com, 知乎：Scheisse egal `}
        pathname="/contact/"
      />
      <main className={styles.main}>
        <div className={styles.content}>
          <div className={styles.title}>
            <div>YidOngCai</div>
            <div>蔡依东</div>
          </div>
          <div className={styles.address}>
            <div>常驻_</div>
            <div>上海/长沙</div>
          </div>
          <div className={styles.email}>
            <div>EMail_</div>
            <p>
              <a href="mailto:migrate_cyd008@163.com">migrate_cyd008@163.com</a>
            </p>
            <p>
              <a href="mailto:migratecyd008@gmail.com">
                migratecyd008@gmail.com
              </a>
            </p>
          </div>
          <div className={styles.wechat}>
            <div>微信_</div>
            <div>
              cyd84609<span>（商务合作）</span>
            </div>
          </div>

          <div className={styles.en}>
            <div>Base in_Shanghai/Changsha</div>
            <div>
              EMail_
              <a href="mailto:migrate_cyd008@163.com">migrate_cyd008@163.com</a>
            </div>
            <div className={styles.gmail}>
              _
              <a href="mailto:migratecyd008@gmail.com">
                migratecyd008@gmail.com
              </a>
            </div>
            <div className={styles.wechat_en}>
              Wechat_cyd84609<span>(Business Contact)</span>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}
